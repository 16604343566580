import {Container, Navbar} from "react-bootstrap";
import packageJson from '../../package.json';

export default function Footer() {
    return (
        <Navbar bg="dark" variant={'dark'} className={'mt-auto'}>
            <Container>
                <Navbar.Text className={'mx-auto'}>
                    &copy; {new Date().getFullYear()} Micah Yeager — v{packageJson.version}
                </Navbar.Text>
            </Container>
        </Navbar>
    )
}
