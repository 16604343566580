import React from 'react';
import {Card} from "react-bootstrap";
import './ServiceShared.scss';
import './Service.scss';
import {service} from "../interfaces";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnglesRight} from "@fortawesome/free-solid-svg-icons";

// function component
export default function Service(props: service) {
    return (
        <a href={props.host} target={'_blank'} rel={'noreferrer'} className={'service-wrapper text-decoration-none'}>
            <Card className={'bg-dark text-light'}>
                <Card.Img variant="top" src={process.env.PUBLIC_URL + props.logo} className={'p-3 bg-primary'}/>
                <Card.Body className={'d-flex flex-column'}>
                    <Card.Title>{props.name} — {props.genericName}</Card.Title>
                    <Card.Text>{props.description}</Card.Text>
                    <Card.Text
                        className={'d-inline-block w-100 text-white text-center mt-auto'}>
                        Go
                        <FontAwesomeIcon icon={faAnglesRight} className={'ms-2'}/>
                    </Card.Text>
                </Card.Body>
            </Card>
        </a>
    );
}
