import {Tab} from "react-bootstrap";
import Service from "./Service";
import {serviceGroup} from "../interfaces";
import ServiceFix from "./ServiceFix";
import Constants from "../constants";

interface Props {
    services: serviceGroup[];
}

export default function Content(props: Props) {
    const services = props.services;

    // iterate through each service group and collect services that have the "fix" attribute
    const fixableServices = services.map((group, _) => {
        return group.items.filter((service) => {
            return service.fix !== undefined;
        })
    }).flat();

    return (
        <Tab.Content>
            {/* dynamically-generated panes */}
            {services.map((group, _) => {
                return <Tab.Pane key={group.key} eventKey={group.key} className={'p-2 py-sm-4 px-md-5'}>
                    {group.items.map((service, _) => {
                        return <Service {...service}/>
                    })}
                </Tab.Pane>
            })}
            {/* fix issues pane */}
            <Tab.Pane key={Constants.FIX_ISSUES_KEY} eventKey={Constants.FIX_ISSUES_KEY}
                      className={'p-2 py-sm-4 px-md-5'}>
                {fixableServices.map((service, _) => {
                    return <ServiceFix {...service}/>
                })}
            </Tab.Pane>
        </Tab.Content>
    )
}
