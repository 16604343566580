import React from 'react';
import './App.scss';
import {Tab} from "react-bootstrap";
import services from "./services.json";
import {Content, Footer, HeaderNav} from "./components";
import Constants from "./constants";

export default function App() {
    let defaultKey = services[0].key;

    // get anchor from url
    const anchor = window.location.hash;
    // check if anchor is in services
    if (anchor) {
        // slice # from anchor
        const key = anchor.slice(1);
        if (services.find((group) => group.key === key)) {
            defaultKey = key;
        } else if (key === Constants.FIX_ISSUES_KEY) {
            defaultKey = Constants.FIX_ISSUES_KEY;
        }
    }
    // set tab key
    const [tabKey, setTabKey] = React.useState<string>(defaultKey);

    return (
        <div className="App d-flex flex-column min-vh-100">
            <Tab.Container activeKey={tabKey}>
                <HeaderNav services={services} setTabKey={setTabKey}/>
                <Content services={services}/>
            </Tab.Container>
            <Footer/>
        </div>
    );
}
