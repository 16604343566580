import {service, serviceFix} from "../interfaces";
import {Button, Card, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faHammer} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import './ServiceShared.scss'

export default function ServiceFix(props: service) {
    // cast to serviceFix since we know it has the fix attribute
    const fix = (props.fix as serviceFix);

    // set state
    const [finished, setFinished] = React.useState<boolean | null>(null);

    function handleClick() {
        // disable button
        setFinished(false);
        // send webhook, suppressing any errors
        fetch(fix.webhookUrl, {method: 'POST', mode: 'no-cors'}).finally(() => {
            setFinished(true);
        });
    }

    return (
        <Card className={'bg-dark text-light'}>
            <Card.Img variant="top" src={process.env.PUBLIC_URL + props.logo} className={'p-3 bg-primary'}/>
            <Card.Body>
                <Card.Title>{props.name} — {props.genericName}</Card.Title>
                <Card.Text>{fix.description}</Card.Text>
                {/* use === to differentiate null and false */}
                <Button variant={finished === null ? 'danger' : 'success'} size={'lg'} onClick={handleClick}
                        disabled={finished === false}
                        className={'mt-auto w-100' + (finished ? 'btn-success' : '')}>
                    {/* icon */}
                    <div className={'service-btn-icon me-2 d-inline-block'}>
                        {/* use === to differentiate null and false */}
                        {finished === null &&
                          <FontAwesomeIcon icon={faHammer}/>
                        }
                        {/* if not finished */}
                        {finished === false &&
                          <Spinner as={'span'} animation={'grow'} size={'sm'} role="status" aria-hidden="true"/>
                        }
                        {/* if finished */}
                        {finished &&
                          <FontAwesomeIcon icon={faCheck}/>
                        }
                    </div>
                    {/* text */}
                    {finished ? 'Done!' : 'Fix'}
                </Button>
            </Card.Body>
        </Card>
    )
}
