import {Container, Nav, Navbar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBellConcierge, faScrewdriverWrench} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {serviceGroup} from "../interfaces";
import Constants from "../constants";

interface Props {
    services: serviceGroup[];
    setTabKey: (key: string) => void;
}

export default function HeaderNav(props: Props) {
    const services = props.services;
    const setTabKey = props.setTabKey;

    return (
        <Navbar bg="dark" variant={'dark'} expand={'md'}>
            <Container>
                <Navbar.Brand>
                    <FontAwesomeIcon icon={faBellConcierge} className={'mx-2'}/>
                    Yeager services
                </Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse>
                    {/* add top margin when nav is collapsed, and make navbar take up entire horizontal space for better
                    spacing when nav is not collapsed */}
                    <Nav variant="pills" className={'mt-2 mt-md-0 w-100'}
                         onSelect={(key) => setTabKey(key as string)}>
                        {services.map((group, _) => {
                            return <Nav.Item key={group.key}>
                                {/* add spacing between items when nav is collapsed */}
                                <Nav.Link className={'px-2 mb-1 mb-sm-0'} eventKey={group.key}
                                          href={'#' + group.key}>
                                    {group.displayName.replace(/^./, str => str.toUpperCase())}
                                </Nav.Link>
                            </Nav.Item>
                        })}
                        {/* when nav is not collapsed, move fix button to right */}
                        <Nav.Item className={'ms-md-auto me-md-0'}>
                            {/* add spacing between items when nav is collapsed */}
                            <Nav.Link className={'px-2 mb-1 mb-sm-0 bg-danger'} href={'#' + Constants.FIX_ISSUES_KEY}
                                      eventKey={Constants.FIX_ISSUES_KEY}>
                                <FontAwesomeIcon icon={faScrewdriverWrench} className={'me-2'}/>
                                Fix issues
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
